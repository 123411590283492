import { render, staticRenderFns } from "./article-category-edit.vue?vue&type=template&id=5b2e8423&scoped=true&"
import script from "./article-category-edit.vue?vue&type=script&lang=ts&"
export * from "./article-category-edit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b2e8423",
  null
  
)

export default component.exports